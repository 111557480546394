<template>
  <div style="background: #fff;height: 100%;
">
    <div style="overflow: hidden;background: #fff;padding-top:0">
      <div class="h1">{{list.activityName}}</div>
      <div class="biao">
        <div class="biao_a">
          <div class="nei_a">总订单数</div>
          <div class="nei_b">{{list.orderTotal}}</div>
          <div class="nei_c">备注</div>
        </div>
        <div class="biao_a">
          <div class="nei_a">总金额</div>
          <div class="nei_b" style="color: red">{{list.orderAmount?list.orderAmount.toFixed(2):''}}</div>
          <div
            class="nei_c"
          >{{list.orderTotal}}*{{list.activityPrice}}={{list.orderAmount?list.orderAmount.toFixed(2):''}}</div>
        </div>
        <!-- <div class="biao_a">
          <div class="nei_a">红包总金额</div>
          <div class="nei_b">{{list.redTotal?list.redTotal.toFixed(2):''}}</div>
          <div class="nei_c">直接红包+二级红包+购买红包+分享红包</div>
        </div>-->
        <div class="biao_a">
          <div class="nei_a">上线设计费</div>
          <div class="nei_b">580.00</div>
          <div class="nei_c"></div>
        </div>
        <!-- <div class="biao_a">
          <div class="nei_a">结算金额</div>
          <div class="nei_b">{{list.actualAmount?list.actualAmount.toFixed(2):''}}</div>
          <div class="nei_c"></div>
        </div> -->
        <div class="biao_a">
          <div class="nei_a">直接红包个数</div>
          <div class="nei_b">{{list.firstCount}}</div>
          <div class="nei_c"></div>
        </div>
        <div class="biao_a">
          <div class="nei_a">直接红包金额</div>
          <div class="nei_b">{{list.firstAmount?list.firstAmount.toFixed(2):''}}</div>
          <div class="nei_c">{{list.firstCount}}*{{list.firstRed}}={{list.firstCount*list.firstRed}}</div>
        </div>
        <div class="biao_a">
          <div class="nei_a">二级红包个数</div>
          <div class="nei_b">{{list.secondCount}}</div>
          <div class="nei_c"></div>
        </div>
        <div class="biao_a">
          <div class="nei_a">二级红包金额</div>
          <div class="nei_b">{{list.secondAmount?list.secondAmount.toFixed(2):''}}</div>
          <div class="nei_c">
            <span
              v-if="list.secondAmount"
            >{{list.secondCount}}*{{list.secondRed}}={{list.secondAmount?list.secondAmount.toFixed(2):''}}</span>
          </div>
        </div>
        <div class="biao_a">
          <div class="nei_a">购买红包金额</div>
          <div class="nei_b"></div>
          <div class="nei_c"></div>
        </div>
        <div class="biao_a">
          <div class="nei_a">分享红包个数</div>
          <div class="nei_b">{{list.shareCount}}</div>
          <div class="nei_c"></div>
        </div>
        <div class="biao_a">
          <div class="nei_a">分享红包金额</div>
          <div class="nei_b">{{list.shareAmount?list.shareAmount.toFixed(2):''}}</div>
          <div class="nei_c">
            <span
              v-if="list.shareAmount"
            >{{list.shareCount}}*{{list.shareRed}}={{list.shareAmount?list.shareAmount.toFixed(2):''}}</span>
          </div>
        </div>
        <div class="biao_a">
          <div class="nei_a">1%提现手续费</div>
          <div class="nei_b">{{Math.ceil(list.orderAmount)*list.inCashRate}}</div>
          <div class="nei_c">{{list.orderAmount}}*{{list.inCashRate}}={{Math.ceil(list.orderAmount)*list.inCashRate}}</div>
        </div>
        <div class="biao_a">
          <div class="nei_a">短信费用</div>
          <div class="nei_b">{{list.orderTotal?(list.orderTotal*0.1).toFixed(2):''}}</div>
          <div class="nei_c">{{list.orderTotal}}*0.1={{list.orderTotal?(list.orderTotal*0.1).toFixed(2):''}}</div>
        </div>
        <div class="biao_a">
          <div class="nei_a">集客手续费</div>
          <div class="nei_b">{{list.gcamount?list.gcamount.toFixed(2):''}}</div>
          <div class="nei_c">
            <span
              v-if="list.gcamount"
            >{{list.orderTotal}}*{{list.gcprice}}={{list.gcamount?list.gcamount.toFixed(2):''}}</span>
          </div>
        </div>
        <!-- <div class="biao_a">
          <div class="nei_a">电销费用</div>
          <div class="nei_b">{{list.esamount?list.esamount.toFixed(2):''}}</div>
          <div class="nei_c"></div>
        </div>
        <div class="biao_a">
          <div class="nei_a">代发红包</div>
          <div class="nei_b">{{list.proxyRedAmount?list.proxyRedAmount.toFixed(2):''}}</div>
          <div class="nei_c"></div>
        </div> -->
        <div class="biao_a">
          <div class="nei_a">实际提现金额</div>
          <div
            class="nei_b"
            style="color: red"
          >{{list.actualCashAmount?(list.actualCashAmount-(list.orderTotal*0.1)-580).toFixed(2):''}}</div>
          <div class="nei_c" style="color: red;">
            <!-- 结算金额-提现手续费-集客手续费 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
export default {
  data() {
    return {
      gid: "",
      aid: this.$route.query.aid,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      content: [],
      uid: "",
      list: {}
    };
  },
  methods: {},
  created() {
    // this.userInfo.userId 3418
    userModel.tongjiorder({ aid: this.aid }).then(res => {
      if (res.code == 200) {
        this.list = res.data;
        this.content = res.data.redPackLogDOPage.content;
      }
    });
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
.h1 {
  text-align: center;
  font-size: 16px;
  line-height: 40px;
}

.biao {
  border: 1px solid #333;
  border-bottom: none;
  /* height: 500px; */
  font-size: 14px;

  margin: 0 10px;
}

.biao_a {
  display: flex;
  display: -webkit-flex;
  /* Safari */
  /* flex-direction:column; */
  /* height: 30px; */

  border-bottom: 1px solid #333;
  overflow: hidden;
}

.nei_a {
  float: left;
  width: 25%;
  /* line-height: 30px; */

  text-align: center;
  border-right: 1px solid #333;
  flex-direction: column;
  padding: 5px 0;
}

.nei_b {
  text-align: right;
  float: left;
  width: 25%;
  /* line-height: 30px; */
  border-right: 1px solid #333;
  flex-direction: column;
  padding: 5px 10px;
}

.nei_c {
  flex-direction: column;
  /*      height: 30px;*/
  float: left;
  padding: 5px 10px;
  width: 50%;
}
</style>